$theme-colors: (
  "primary": #1890ff,
  "dark-blue": #001529,
  "orange": #fd8650,
  "grey": #a5a5a5,
  "lightGrey": #f0f2f5,
  "red": #ff0000,
  "transOrange": hsla(20, 71%, 84%, 0.2),
  "transRed": #ffa8a8,
  "transPrimary": rgba(193, 225, 255, 0.2),
);

@import "~bootstrap/scss/bootstrap.scss";
