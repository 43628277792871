.formMapContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.mapInItem {
  width: 100%;

  transition: all 0.2s ease-in-out;
  transform-origin: center;
}
.showRoomFomItem {
  width: 60%;
  height: 100%;
}

.mapContainer {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
