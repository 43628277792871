@font-face {
  font-family: AirBnb;
  font-weight: 200;
  src: url("../public/fonts/AirbnbCereal_W_Lt.otf") format("opentype");
}
@font-face {
  font-family: AirBnb;
  font-weight: 300;
  src: url("../public/fonts/AirbnbCereal_W_Bk.otf") format("opentype");
}

@font-face {
  font-family: AirBnb;
  font-weight: 400;
  src: url("../public/fonts/AirbnbCereal_W_Md.otf") format("opentype");
}
@font-face {
  font-family: AirBnb;
  font-weight: 700;
  src: url("../public/fonts/AirbnbCereal_W_Blk.otf") format("opentype");
}
@font-face {
  font-family: AirBnb;
  font-weight: 500;
  src: url("../public/fonts/AirbnbCereal_W_Bd.otf") format("opentype");
}
@font-face {
  font-family: AirBnb;
  font-weight: 600;
  src: url("../public/fonts/AirbnbCereal_W_XBd.otf") format("opentype");
}

.App {
  text-align: center;
  background-color: #e5e5e5;
}
* {
  font-family: AirBnb;
  font-size: 16px;
}
a {
  text-decoration: none !important;
}

.pointer {
  cursor: pointer;
}
i {
  cursor: pointer;
  display: block;
}
.accordionCustom {
  border: none !important;
}

/********************* CUSTOM WIDTH **************************/

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 40% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 40% !important;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-98 {
  width: 98%;
}

/********************* CUSTOM FONT-WEIGHT **************************/

.lighter {
  font-weight: 200;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.bolder {
  font-weight: 600;
}
.bold {
  font-weight: 500;
}
.xbold {
  font-weight: 700;
}

/********************* CUSTOM FONT-SIZE **************************/

.f10 {
  font-size: 10px;
}
.f12 {
  font-size: 12px;
}
.f14 {
  font-size: 14px;
}
.f16 {
  font-size: 16px;
}
.f18 {
  font-size: 18px;
}
.f20 {
  font-size: 20px;
}
.f24 {
  font-size: 24px;
}
.f26 {
  font-size: 26px;
}
.f40 {
  font-size: 40px;
}
.f48 {
  font-size: 48px;
}
.f60 {
  font-size: 60px;
}

/********************* CUSTOM BORDER RADIUS **************************/
.rounded-10 {
  border-radius: 0.625rem !important;
}
.t02 {
  transition: all 0.2s ease-in-out;
}
.h20 {
  height: 40px;
}

.rounded-bottom-10 {
  border-bottom-right-radius: 0.625rem !important;
  border-bottom-left-radius: 0.625rem !important;
}
.accordion-button {
  border-radius: 0.625rem !important;
}

/* .shadowed {
  box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.08), 0px 7.43288px 25.9105px rgba(0, 0, 0, 0.0247343);
} */
.customSelect {
  background-color: #9ca3af1a !important;
  border: none !important;
  height: 40px !important;
}
.customSelect:focus {
  box-shadow: none !important;
  border: none !important;
}
.shadowed {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.mx-w-280 {
  max-width: 280px !important;
  min-width: 280px !important;
}
