.fileListContainer {
  gap: 40px;
}
.filesFilter {
  margin-bottom: -5px;
  cursor: pointer;
  padding-bottom: 4px;
  transition: all 0.1s ease-in-out;
  min-width: fit-content;
  max-width: 300px;
}
.filesFilterSelected {
  cursor: pointer;
  border-bottom: #14748e 3px solid;
  color: #051a30;
  font-weight: bold;
  transform: scale(1.1);
}
.fileFilterContainer {
  border-bottom: 2px solid #e8edf3;
  border-collapse: collapse;
  padding-bottom: 4px;
  margin: auto;
  width: max-content;
}
