.logoContainerLogin {
  background-image: url("../../images/logo_poseidon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 300px;
  height: 100px;
}
.loginContainer {
  background-image: url("../../images/loginBG.png");
}
.loginGlass {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(7.5px);
  -webkit-backdrop-filter: blur(7.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
