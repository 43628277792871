.fileCard {
  border: 1px solid rgb(65, 65, 65);
  height: 250px !important;
  min-width: 300px;
  width: 15%;
  overflow: hidden;
  border-radius: 5%;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  transform: scale(0.9);
}
.fileCard:hover {
  transform: scale(1);
}
.fileCard:hover .fileCardFilter {
  opacity: 0;
}
.fileCard:hover .fileCardDeleteIcon {
  opacity: 1;
}
.fileCardFilter {
  background-color: black;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  transition: all 0.2s ease-in-out;
}
.fileCardImage {
  width: 100%;
}
.fileCardImageName {
  position: absolute;
  bottom: 10px;
  word-break: break-all;
}
.fileCardDeleteIcon {
  display: block;
  background-color: #fff;
  font-size: 20px;
  position: absolute;
  top: 15px;
  right: 15px;
  color: red;
  opacity: 0;
  border: 1px solid red;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}
.fileCardRename {
  position: absolute;
  bottom: 10px;
  right: 15px;
  color: #fd8650;
  border: 1px solid #fd8650;
  padding: 2px 5px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  z-index: 2;
}
.fileCardRename:hover {
  transform: scale(1.2);
}
.fileCardDeleteIcon:hover {
  background-color: red;
  color: white;
  transform: scale(1.2);
}
.fileCardDeleteIcon:active {
  transform: scale(1.5);
  background-color: rgb(255, 0, 0, 0.1);
  border-color: rgb(255, 0, 0, 0.1);
  color: rgb(255, 255, 255, 0.1);
}
.fileCardRename:active {
  transform: scale(1.5);
  background-color: rgb(253, 134, 80, 0.1);
  border-color: rgb(253, 134, 80, 0.1);
  color: rgb(253, 134, 80, 0.1);
}
.renameFileField {
  border: 1px solid rgb(133, 133, 133);
  border-radius: 5px;
  outline: none;
}
