.flagInput {
  width: 20px;
  height: 20px;
  background-size: contain;
  opacity: 0.1;
  transition: all 0.2s ease-in-out;
}
.flagFocused {
  opacity: 0.9;
}
.jodit-react-container {
  width: 100% !important;
}

.styleInput {
  background-color: #9ca3af1a;
  border-color: #9ca3af1a;
  opacity: 0.8;
  min-height: 40px;
  border: none;
  transition: all 0.2s ease-in-out;
  outline: none;
  word-break: break-all;
}
.styleInput {
  opacity: 1;
}
.inputArea {
  height: 90px;
}

.flaginputfr {
  background-image: url("../../../images/flag_france.png");
}
.flaginputen {
  background-image: url("../../../images/flag_uk.png");
}
.readOnlyArea {
  height: 150px;
}
